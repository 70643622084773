const cookiesDialog = document.querySelector('.cookies-dialog');

const cookiesAgreeBtn = cookiesDialog?.querySelector('.cookies-dialog__buttons button');


if (cookiesAgreeBtn) {
  cookiesAgreeBtn.onclick = () => {
    const cookieName = 'T_KYIV_AGREEMENT';
    cookiesDialog.classList.add('hidden');
    const expiryDate = new Date();
    expiryDate.setMonth(expiryDate.getMonth() + 1);
    document.cookie = `${cookieName}=y; expires=${expiryDate.toGMTString()}; path=/`;
  }
}